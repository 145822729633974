<template>
  <div>
    <div class="row mt-2">
      <div class="col-xl-8">
        <div class="row">
          <div class="col-sm-3 mb-2">
            <span class="c-toolbar__state-title u-text-capitalize">Search</span>
            <input class="form-control" type="text" placeholder="Search" />
          </div>
          <div class="col-sm-3 mb-2">
            <span class="c-toolbar__state-title u-text-capitalize">Options</span>
            <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
          </div>
          <div class="col-sm-3 mb-2">
            <span class="c-toolbar__state-title u-text-capitalize">Sort By</span>
            <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
          </div>
          <div class="col-sm-3 mb-2">
            <span class="c-toolbar__state-title u-text-capitalize">Sales Rep</span>
            <date-picker format="YYYY-MM-DD" style="width: 100%;" lang="en"></date-picker>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="row">
          <div class="col-xl-8 col-lg-6 col-sm-6">
            <div class="ss--project-action-bar__btns info-filter-group-btn">
              <span class="c-toolbar__state-title u-text-capitalize opacity-0">Sales Rep</span>
              <div class="group-btn-holder">
                <div class="btn-group">
                  <a class="btn ss-primary-font-color ss-light-grey-bg" href="#!">
                    <i class="fa fa-print u-mr-xsmall"></i>Print
                  </a>
                  <a class="btn ss-primary-font-color ss-light-grey-bg" href="#!">
                    <i class="fa fa-envelope u-mr-xsmall"></i>Email
                  </a>
                  <a class="btn ss-primary-font-color ss-light-grey-bg" href="#!">
                    <i class="fa fa-envelope u-mr-xsmall"></i>Email
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-sm-6">
            <div>
              <span class="c-toolbar__state-title u-text-capitalize opacity-0">Sales Rep</span>
              <a
                class="c-btn ss-warning-bg ss-white-font-color u-border-zero u-width-100"
                href="#!"
              >
                <i class="fa fa-gear u-mr-xsmall"></i>Filters
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {};
  },
  components: {
    DatePicker
  }
};
</script>

